import { DirectiveBinding } from 'vue/types/options';
import { DirectiveOptions } from 'vue/types/umd';

interface InputAutosizeDirective extends DirectiveOptions {
  mirror: HTMLDivElement | null;
  _check: (el: HTMLElement, binding: DirectiveBinding) => void;
}

const defaults = {
  maxWidth: 1000,
  minWidth: 10,
  comfortZone: 0,
  fitToCell: false,
};

const directive: InputAutosizeDirective = {
  mirror: null,

  bind(el: HTMLElement) {
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
      throw new Error('v-input-autowidth can only be used on input elements.');
    }

    el.dataset.uuid = Math.random()
      .toString(36)
      .slice(-5);

    el.style.boxSizing = 'content-box';
  },

  inserted(el: HTMLElement, binding: DirectiveBinding) {
    directive.mirror = document.createElement('div');
    directive.mirror.classList.add('vue-input-autosize-mirror');

    el.addEventListener('input', () => directive._check(el, binding), false);

    setTimeout(() => {
      const styles = window.getComputedStyle(el, null);

      if (!directive.mirror) {
        return;
      }

      Object.assign(directive.mirror.style, {
        position: 'absolute',
        top: '-9999px',
        left: '-9999px',
        width: 'auto',
        whiteSpace: 'nowrap',
        opacity: 0,
        border: styles.getPropertyValue('border'),
        fontSize: styles.getPropertyValue('font-size'),
        fontFamily: styles.getPropertyValue('font-family'),
        fontWeight: styles.getPropertyValue('font-weight'),
        letterSpacing: styles.getPropertyValue('letter-spacing'),
        padding: styles.getPropertyValue('padding'),
        textTransform: styles.getPropertyValue('text-transform'),
        ariaHidden: true,
      });

      document.body.appendChild(directive.mirror);

      directive._check(el, binding);
    }, 0);
  },

  update(el: HTMLElement, binding: DirectiveBinding) {
    directive._check(el, binding);
  },

  unbind(el: HTMLElement, binding: DirectiveBinding) {
    // document.body.removeChild(directive.mirror);
    el.removeEventListener('input', () => directive._check(el, binding));
  },

  _check(el: HTMLElement, binding: DirectiveBinding, value: string | null = null) {
    if (!directive.mirror) {
      return;
    }

    const input = el as HTMLInputElement;
    const val = value || input.value || input.placeholder || '';
    const options = Object.assign({}, defaults, binding.value);

    directive.mirror.innerHTML = val
      .replace(/&/g, '&amp;')
      .replace(/\s/g, '&nbsp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    let newWidth = directive.mirror.getBoundingClientRect().width + options.comfortZone;
    let unit = 'px';

    let tableCellAncestor;

    if (options.fitToCell) {
      tableCellAncestor = el.closest('td');
    }

    if (tableCellAncestor) {
      const computedStyle = getComputedStyle(tableCellAncestor);
      const tableCellAncestorWidth = tableCellAncestor.offsetWidth -
        parseInt(computedStyle.paddingLeft, 0) -
        parseInt(computedStyle.paddingRight, 0);

      if (newWidth >= tableCellAncestorWidth) {
        unit = '%';
        newWidth = 100;
      }
    } else {
      if (newWidth > options.maxWidth) {
        newWidth = options.maxWidth;
      } else if (newWidth < options.minWidth) {
        newWidth = options.minWidth;
      }
    }

    if (newWidth !== el.getBoundingClientRect().width) {
      el.style.width = newWidth + unit;
    }
  },
};

export default directive;
